<template>
  <div>
    <h1>Pagamento eseguito con successo</h1>
    tra un attimo sarai reindirizzato alla tua dashboard
  </div>
</template>

<script>
import AxiosService from "@/axiosServices/AxiosService"
export default {
  name: "StripeSuccess",
  mounted() {
    this.setFatturaPagata()
  },
  methods: {
    setFatturaPagata() {
      const service = new AxiosService(
        `Plugin/AutoInvoice/SetPaid/${this.$route.params.idFattura}`
      )

      service
        .read()
        .then((res) => {
          setTimeout(() => {
            this.$router.push("/fdt-licence")
          }, 3000)
        })
        .catch((err) => {
          console.log(err)
        })
    },
  },
}
</script>
